<template>
  <div class="BG">
    <el-card class="box-card">
      <el-row  v-if="loginType==false">
      <el-form :model="loginform">
        <el-form-item class="LOGO">
           <el-image
                :src="loginform.url"
                fit="cover"></el-image>
        </el-form-item>
        <el-form-item class="UNbox">
          <div class="useName">橙电售后管理系统</div>
        </el-form-item>
        <div>
          <el-form-item>
            <el-input v-model="loginform.phone" :placeholder="$t('登录名')"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input type="password" v-model="loginform.password" :placeholder="$t('密码')"></el-input>
          </el-form-item>
          <el-form-item class="operationPass">
              <!-- <el-checkbox v-model="loginform.checked" label="记住密码"></el-checkbox> -->
              <el-button type="text" class="toggle" @click='loginType=true'>{{$t('扫码登录')}}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button class="loginBtn" @click="isLogin">{{$t('登录')}}</el-button>
          </el-form-item>
        </div>
       </el-form>
      </el-row>
      <div class="QR_Login" v-if="loginType==true">
        <el-button type="text" class="toggle" @click='loginType=false'>{{$t('密码登录')}}</el-button>
        <div class="QR_Code">
          <iframe :src="QRCodeUrl"></iframe>
          <div class="code_mask" v-if="overdue" @click="Reacquire">
           <!-- 二维码已过期 -->
           <i class="bi bi-arrow-clockwise"></i>
          </div>
        </div>
        <div class="Tips" v-if="overdue">{{$t('二维码已过期,请点击重新获取')}}</div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loginform: {
        url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        phone: '',
        password: '',
        checked: false
      },
      loginType: false,
      codeUrl: '',
      overdue: false,
      fullscreenLoading: false,
      QRCodeUrl: ''
    }
  },
  created () {
    // console.log(this.common.api) ?token=${window.sessionStorage.getItem('token')}&

  },
  mounted () {
    if (document.domain !== 'localhost') {
      document.domain = 'orangeevcharge.com'
    }
    this.QRCodeUrl = `${this.$axios.defaults.baseURL}admin/wxscan_login`
    var that = this
    // 扫码登录事件
    window.scanLoginSuccess = function (token) {
      var loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      if (token) {
        window.sessionStorage.setItem('token', token)
        that.$common.userInfo().then((res) => {
          // console.log(res)
          var data = res.data
          that.$common.setCookie('username', data.data.username)
          that.$common.setCookie('phone', data.data.phone)
          window.localStorage.setItem('user_role', JSON.stringify(data.data.user_role))
          window.sessionStorage.setItem('orange_lang','zh')
          loading.close()
          that.$router.push('/')
        }).catch((error) => {
          loading.close()
          this.$message.error(error)
          console.log(error)
        })
      }
    }
    // 按回车登录事件
    window.onkeyup = function (e) {
      // console.log(e)
      if (e.keyCode === 13) {
        that.isLogin()
      }
    }
  },

  methods: {
    // 判断账号密码是否正确
    isLogin () {
      var loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      this.$axios.post('admin/login', {
        phone: this.loginform.phone,
        password: this.loginform.password
      }).then((res) => {
        // console.log('res', res)
        if (res.data.code === 0) {
          const data = res.data
          if (!data.data.token || data.data.token === undefined) return
          window.sessionStorage.setItem('token', data.data.token)
          this.$common.userInfo().then((res) => {
            // console.log(res)
            var data = res.data
            this.$common.setCookie('username', data.data.username)
            this.$common.setCookie('phone', data.data.phone)
            // console.log(JSON.stringify(data.data.user_role))
            window.localStorage.setItem('user_role', JSON.stringify(data.data.user_role))
            window.sessionStorage.setItem('orange_lang','zh')
            // this.$store.user_role = data.data.user_role
            loading.close()
            this.$router.push('/')
          }).catch((error) => {
            loading.close()
            this.$message.error(error)
            console.log(error)
          })
        } else {
          loading.close()
          this.$message.error(res.data.message)
        }
      }).catch((error) => {
        console.log(error)
        loading.close()
      })
    },
    // 重新获取二维码
    Reacquire () {
      this.overdue = false
    }
  }
}
</script>

<style scoped>
  .box-card {
    /* 520px */
    width: 520px;
    /* 520px */
    height: 520px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* 10px */
    border-radius: 0.625rem;
  }
  .LOGO,.UNbox{
    text-align: center;
  }
  .LOGO{
    /* 20px */
    margin: 1.25rem 0 0 0;
  }
  .el-image{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  .useName{
    font-weight: 700;
    user-select: none;
  }
  .loginBtn{
    width: 100%;
    background: #ff5722;
    color: #ffffff;
    border: 0;
  }
  .loginBtn:focus,
  .loginBtn:hover{
    background: #f85220;
    color: #ffffff;
    border: 0;
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #ff5722;
      border-color: #ff5722;
  }
  ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #606266;
  }
  ::v-deep .el-form-item__content{
    width: 100%;
  }
  .toggle{
    /* float: right; */
    width: 100%;
    text-align: right;
  }
  ::v-deep .el-card__body{
    height: 100% !important;
  }
  .QR_Code{
    /* 200px */
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    /* 13px */
   /* padding: 0.8125rem; */
    position: relative;
  }
  .QR_Login{
     height: 100%;
  }
  .QR_Code>iframe{
    width: 100%;
    height: 100%;
    border: 0 !important;
  }
  .code_mask{
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    user-select: none;
  }
  .Tips{
    text-align: center;
    margin: 10px 0;
    font-size: 15px;
  }
  .bi-arrow-clockwise{
    font-size: 30px;
    /* opacity: 1; */
  }
</style>
